import { type Ref } from "vue";
import { useQuery, useMutation, useQueryClient } from "@tanstack/vue-query";
import { meetingService } from "@/core/services/meeting.service";
import { useMeetingId, useStagiaireId } from "./routes";
import type {
  PatchedOwnMeetingUpdateParticipant,
  PatchedOpinionParticipant,
  PatchedMeetingParticipant,
} from "@sgdf/client";

export const getParticipantKey = (meetingId: string) => [
  "meetings",
  meetingId,
  "participants",
];

export const useParticipants = () => {
  const meetingId = useMeetingId();

  const hasPermission = usePermission();
  const enabled = ref(false);

  watchEffect(() => {
    enabled.value =
      !!meetingId && hasPermission.value("view_participant", meetingId);
  });

  return useQuery({
    queryKey: getParticipantKey(meetingId),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsList({
          meetingUuid: meetingId,
          uuid: meetingId,
        }),
    enabled,
  });
};

export const useOpinionParticipants = () => {
  const meetingId = useMeetingId();
  const hasPermission = usePermission();
  const enabled = ref(false);

  watchEffect(() => {
    enabled.value =
      !!meetingId && hasPermission.value("view_participant", meetingId);
  });

  return useQuery({
    queryKey: [...getParticipantKey(meetingId), "opinion-list"],
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsOpinionListList({
          meetingUuid: meetingId,
          uuid: meetingId,
        }),
    enabled,
  });
};
export const useParticipant = (participantId: ComputedRef<string | null>) => {
  const meetingId = useMeetingId();
  const hasPermission = usePermission();
  const enabled = ref(false);

  watchEffect(() => {
    enabled.value =
      !!meetingId &&
      hasPermission.value("view_participant", meetingId) &&
      !!participantId.value;
  });

  return useQuery({
    queryKey: getParticipantKey(meetingId),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsRetrieve({
          meetingUuid: meetingId,
          uuid: participantId.value!,
        }),
    enabled,
  });
};

export const useOpinionParticipant = (
  participantId: ComputedRef<string | null>
) => {
  const meetingId = useMeetingId();
  const hasPermission = usePermission();
  const enabled = ref(false);

  watchEffect(() => {
    enabled.value =
      !!meetingId &&
      hasPermission.value("view_participant", meetingId) &&
      !!participantId.value;
  });

  return useQuery({
    queryKey: [...getParticipantKey(meetingId), "opinion"],
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsOpinionRetrieve({
          meetingUuid: meetingId,
          uuid: participantId.value!,
        }),
    enabled,
  });
};

export const useAvatarUpload = (participantId: Ref<string>) => {
  const meetingId = useMeetingId();

  const refreshUploadUrl = async () => {
    if (participantId.value) {
      const resp = await meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsRetrieve({
          meetingUuid: meetingId,
          uuid: participantId.value,
        });
      return resp.avatar_url;
    }
    return null;
  };

  return refreshUploadUrl;
};

export const useObservationCountByParticipant = () => {
  const meetingId = useMeetingId();
  const hasPermission = usePermission();

  const enabled = ref(false);

  watchEffect(() => {
    enabled.value =
      !!meetingId && hasPermission.value("view_comment", meetingId);
  });

  return useQuery({
    queryKey: [...getParticipantKey(meetingId), "observation-count"],
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsList({
          meetingUuid: meetingId,
          uuid: meetingId,
        }),
    enabled,
    select: (data) => {
      const interns = data.results?.filter(
        (participant) => participant.role.intranet_id === 0
      );

      return interns?.reduce(
        (acc, p) => ({ ...acc, [p.id]: p.observation_count }),
        {}
      );
    },
  });
};

export const useCurrentStagiaire = () => {
  const meetingId = useMeetingId();
  const stagiaireId = useStagiaireId();

  return useQuery({
    queryKey: ["meetings", meetingId, "participants", stagiaireId],
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsRetrieve({
          meetingUuid: meetingId,
          uuid: stagiaireId.value!,
        }),
  });
};

export const useSearchParticipants = (filter: Ref<string>) => {
  const meetingId = useMeetingId();
  return useQuery({
    queryKey: getParticipantKey(meetingId),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsList({
          meetingUuid: meetingId,
          uuid: meetingId,
          search: filter.value,
          ordering: "first_name",
          pageSize: 10,
        }),
    enabled: Boolean(filter) && Boolean(meetingId),
  });
};

export const useUpdateOpinionParticipantMutation = () => {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      participant: Omit<
        PatchedOpinionParticipant,
        "id" | "meeting_id" | "role" | "admin_first_name" | "admin_last_name"
      > & { id: string }
    ) =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsOpinionPartialUpdate({
          meetingUuid: meetingId,
          uuid: participant.id,
          patchedOpinionParticipant: participant,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getParticipantKey(meetingId) });
    },
  });
};
export const useUpdateParticipantMutation = () => {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      participant: Omit<
        PatchedMeetingParticipant,
        | "id"
        | "user_id"
        | "created"
        | "updated"
        | "meeting"
        | "user"
        | "role"
        | "daily_task_team"
        | "teams"
        | "fonction"
        | "structure_principale"
        | "track"
        | "canceled"
        | "observation_count"
        | "last_sync"
        | "avis_prevalidation"
        | "avis_validation"
        | "avis_validation_gestionnaire"
        | "signature_url"
        | "signature_available"
        | "avatar_url"
        | "avatar_available"
        | "user_avatar_url"
        | "user_avatar_available"
      > & { id: string }
    ) =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsPartialUpdate({
          meetingUuid: meetingId,
          uuid: participant.id,
          patchedMeetingParticipant: participant,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getParticipantKey(meetingId) });
      queryClient.invalidateQueries({
        queryKey: [...getParticipantKey(meetingId), "opinion-list"],
      });
      queryClient.invalidateQueries({
        queryKey: [...getParticipantKey(meetingId), "opinion"],
      });
    },
  });
};

export const useUpdateMyParticipantMutation = () => {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (
      participant: PatchedOwnMeetingUpdateParticipant & { id: string }
    ) =>
      meetingService
        .getInstance()
        .getMeetingsApi()
        .meetingsMyParticipationPartialUpdate({
          uuid: meetingId,
          patchedOwnMeetingUpdateParticipant: participant,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getParticipantKey(meetingId) });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "me",
      });
    },
  });
};

export const useDeleteSignatureMutation = () => {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (participant: { id: string }) =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsResetSignatureDestroy({
          meetingUuid: meetingId,
          uuid: participant.id,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getParticipantKey(meetingId) });
    },
  });
};
